import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { openDB } from 'idb';
import './TonWalletChecker.css';


const TokenAutocomplete = ({ value, onChange, suggestions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [selectedExactToken, setSelectedExactToken] = useState('');
  const dropdownRef = useRef(null);


  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    // Закрытие выпадающего списка при клике вне компонента
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setSelectedExactToken('');
    onChange({ input: newValue, exact: '' });
    setIsOpen(true);
  };

  const handleSelect = (token) => {
    setInputValue(token);
    setSelectedExactToken(token);
    onChange({ input: token, exact: token });
    setIsOpen(false);
  };

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <span key={index} className="highlight">{part}</span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <div className="token-autocomplete" ref={dropdownRef}>
      <div className="token-input-wrapper">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          placeholder="Enter jetton name"
          className="token-input"
        />
        {selectedExactToken && (
          <button
            className="clear-selection"
            onClick={() => {
              setInputValue('');
              setSelectedExactToken('');
              onChange({ input: '', exact: '' });
            }}
          >
            ×
          </button>
        )}
      </div>
      {isOpen && suggestions.length > 0 && (
        <div className="token-suggestions">
          {suggestions.map((token, index) => (
            <div
              key={index}
              className={`token-suggestion-item ${token === selectedExactToken ? 'selected' : ''}`}
              onClick={() => handleSelect(token)}
            >
              {highlightMatch(token, inputValue)}
              {token === selectedExactToken && (
                <span className="selected-indicator">✓</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
const TonWalletChecker = () => {
  const [walletAddresses, setWalletAddresses] = useState(['']);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({ processedCount: 0, totalWallets: 0 });
  const [totalBalance, setTotalBalance] = useState(0);
  const [selectedJetton, setSelectedJetton] = useState('');
  const [sortedDescending, setSortedDescending] = useState(false);
  const [totalJettonBalances, setTotalJettonBalances] = useState({});
  const [availableTokens, setAvailableTokens] = useState([]);
  const [sortedJettonsDescending, setSortedJettonsDescending] = useState(false);

  const [db, setDb] = useState(null); // Храним экземпляр базы данных

  // Cache setup using local storage
  const ttl = 60 * 60 * 1000; // Default TTL is 60 minutes (in milliseconds)

  useEffect(() => {
    const tokens = new Set();
    results.forEach(wallet => {
      wallet.jettons.forEach(jetton => {
        if (jetton.name) {
          tokens.add(jetton.name);
        }
      });
    });
    setAvailableTokens(Array.from(tokens).sort());
  }, [results]);

  const tokenSuggestions = useMemo(() => {
    if (!selectedJetton.input) return availableTokens;
    return availableTokens.filter(token =>
      token.toLowerCase().includes(selectedJetton.input.toLowerCase())
    );
  }, [selectedJetton.input, availableTokens]);

  const handleSortJettons = () => {
    const sortedWallets = [...filteredResults].sort((a, b) => {
      // Find the USD value for the selected token in both wallets
      const jettonA = a.jettons.find(jetton => jetton.name === selectedJetton.exact);
      const jettonB = b.jettons.find(jetton => jetton.name === selectedJetton.exact);
  
      // If the token is not found in a wallet, assign 0
      const usdValueA = jettonA ? parseFloat(jettonA.usdValue) : 0;
      const usdValueB = jettonB ? parseFloat(jettonB.usdValue) : 0;
  
      // Sort in descending order by the USD value of the selected token
      return sortedJettonsDescending ? usdValueB - usdValueA : usdValueA - usdValueB;
    });
  
    // Toggle the sorting order for the next click
    setSortedJettonsDescending(!sortedJettonsDescending);
    setFilteredResults(sortedWallets);
  };
  
  

  
  

  useEffect(() => {
    const initializeDB = async () => {
      const database = await openDB('ton-wallet-db', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('cache')) {
            db.createObjectStore('cache', { keyPath: 'key' });
          }
        }
      });
      setDb(database); // Устанавливаем экземпляр базы данных
    };
    initializeDB();
  }, []);

  const setCache = async (key, data) => {
    if (!db) return; // Проверяем, что база данных инициализирована
    const now = Date.now();
    const tx = db.transaction('cache', 'readwrite');
    const store = tx.objectStore('cache');
    await store.put({ key, data, timestamp: now });
    await tx.done;
  };

  const getCache = async (key) => {
    if (!db) return null; // Проверяем, что база данных инициализирована
    const tx = db.transaction('cache', 'readonly');
    const store = tx.objectStore('cache');
    const cached = await store.get(key);
    if (cached && Date.now() - cached.timestamp < ttl) {
      return cached.data;
    }
    return null;
  };

  const fetchWithCache = async (key, fetchFunc) => {
    const cachedData = await getCache(key);
    if (cachedData) {
      console.log(`Cache hit for key: ${key}`);
      return cachedData; // Возвращаем данные из кэша
    }
    console.log(`Cache miss for key: ${key}. Fetching from API...`);
    const data = await fetchFunc();
    await setCache(key, data); // Кэшируем новые данные
    return data;
  };
  


  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const fetchJettons = async (walletAddress) => {
    try {
      const response = await axios.get(`https://tonapi.io/v2/accounts/${walletAddress}/jettons?currencies=ton,usd,rub&supported_extensions=custom_payload`);
      return response.data.balances || [];
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn(`404 error for wallet: ${walletAddress}, skipping.`);
        return [];
      } else {
        throw error;
      }
    }
  };

  const formatPrice = (price) => {
    if (!price) return '0';
    let formattedPrice = parseFloat(price).toFixed(4);
    if (formattedPrice < 0.001) {
      formattedPrice = parseFloat(price).toFixed(6);
    }
    return formattedPrice;
  };

  const formatJettonBalance = (jetton) => {
    let balance = jetton.balance;
    const decimals = jetton.jetton?.decimals || 0;
    if (decimals > 0) {
      balance = balance / Math.pow(10, decimals);
    }
    return parseFloat(balance).toFixed(4); // Show up to 4 decimal digits
  };
  

  const formatAddress = (address) => {
    return address.length > 10 ? address.slice(0, 6) + '...' + address.slice(-4) : address;
  };

  const formatTonBalance = (nanoton) => {
    return (parseInt(nanoton) / 10 ** 9).toFixed(4); // Show up to 4 decimal digits
  };
  

  const calculateUSDValue = (amount, price) => {
    return amount * (price || 0);
  };

  const handleSubmit = async () => {
    console.log('Starting wallet balance check...');
    setLoading(true);
    setResults([]);
    setProgress({ processedCount: 0, totalWallets: walletAddresses.length });
    
    let totalUSDValueAcrossAllTokens = 0; // This tracks the total USD value for all wallets
    const jettonBalances = {};
    const jettonBalancesInUSD = {};
  
    try {
      const results = [];
      
      for (let i = 0; i < walletAddresses.length; i++) {
        const walletAddress = walletAddresses[i].trim();
        if (!walletAddress) continue;
  
        const cacheKey = walletAddress;
        let attempt = 0;
        let success = false;
  
        while (!success && attempt < 5) {
          try {
            // Check if data is available in the cache
            const cachedData = await getCache(cacheKey);
            if (cachedData) {
              console.log(`Using cached data for wallet: ${walletAddress}`);
              results.push(cachedData); // Add cached data to results
              // Recalculate total balance for the cached data
              totalUSDValueAcrossAllTokens += parseFloat(cachedData.totalUSDValue); // Update with cached USD value
              console.log(`Cached total USD value: ${cachedData.totalUSDValue}`); // Output cached value
              success = true;
              break;
            }
  
            // Fetch fresh data if not found in cache
            await delay(500 * (attempt + 1));
  
            const balanceResponse = await fetchWithCache(cacheKey + '_balance', async () => {
              return axios.get(`https://tonapi.io/v2/blockchain/accounts/${walletAddress}`).then(res => res.data);
            });
  
            const tonPriceResponse = await fetchWithCache('ton_price', async () => {
              return axios.get('https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd')
                .then(res => res.data['the-open-network'].usd);
            });
  
            const tonBalance = formatTonBalance(balanceResponse.balance);
            const tonUSDValue = calculateUSDValue(parseFloat(tonBalance), tonPriceResponse);
  
            console.log(`TON USD Value for wallet ${walletAddress}: ${tonUSDValue}`); // Output TON USD value
  
            const jettonsResponse = await fetchWithCache(cacheKey + '_jettons', async () => {
              return fetchJettons(walletAddress);
            });
  
            let walletUSDValue = tonUSDValue; // Start with TON's USD value
            console.log(`Wallet USD Value after adding TON: ${walletUSDValue}`); // Output after adding TON value
  
            const jettons = jettonsResponse.map(jetton => {
              const balance = parseFloat(formatJettonBalance(jetton));
              const price = parseFloat(jetton.price?.prices?.USD || 0);
              const usdValue = calculateUSDValue(balance, price);
              walletUSDValue += usdValue;
  
              if (!jettonBalances[jetton.jetton?.name]) {
                jettonBalances[jetton.jetton?.name] = 0;
                jettonBalancesInUSD[jetton.jetton?.name] = 0;
              }
              jettonBalances[jetton.jetton?.name] += balance;
              jettonBalancesInUSD[jetton.jetton?.name] += usdValue;
  
              return {
                name: jetton.jetton?.name || 'Unknown',
                price: formatPrice(price),
                count: balance,
                usdValue: usdValue.toFixed(2),
              };
            });
  
            // Find USDT jetton, if available
            const usdtJetton = jettons.find(jetton => 
              jetton.name.toLowerCase().includes('usdt') || 
              jetton.name.toLowerCase().includes('tether')
            ) || {
              name: 'Tether USD',
              price: '1',
              count: 0,
              usdValue: '0',
            };
  
            // Sort and slice the top 5 jettons by USD value
            const otherJettons = jettons
              .filter(jetton => jetton.name !== 'TON' && jetton.name !== usdtJetton.name)
              .sort((a, b) => b.usdValue - a.usdValue)
              .slice(0, 5);
  
            // Combine TON, USDT and other jettons in the final array
            const finalJettons = [
              {
                name: 'TON',
                price: formatPrice(tonPriceResponse),
                count: parseFloat(tonBalance),
                usdValue: tonUSDValue.toFixed(2),
              },
              usdtJetton,
              ...otherJettons,
            ];
  
            // Update the total balance with the current wallet's USD value
            totalUSDValueAcrossAllTokens += walletUSDValue; // Add jettons' USD value to total
            console.log(`Total USD value for wallet ${walletAddress} after jettons: ${walletUSDValue.toFixed(2)}`); // Output wallet total value
  
            // Prepare the result for the wallet
            const result = {
              walletAddress: `Wallet ${i + 1}: ` + formatAddress(walletAddress),
              jettons: finalJettons,
              totalUSDValue: walletUSDValue.toFixed(2),
            };
  
            await setCache(cacheKey, result); // Cache the result
            results.push(result);
            success = true;
          } catch (error) {
            attempt++;
            if (attempt >= 5) {
              console.error(`Failed to fetch data for wallet: ${walletAddress} after multiple attempts.`);
              results.push({
                walletAddress: `Wallet ${i + 1}: ` + formatAddress(walletAddress),
                jettons: [{ name: 'Error', price: '0', count: 0, usdValue: '0' }],
                totalUSDValue: '0',
              });
            }
          }
        }
  
        setProgress({ processedCount: i + 1, totalWallets: walletAddresses.length });
      }
  
      // After processing all wallets, update the total balance (across all tokens)
      console.log(`Total USD value across all wallets: ${totalUSDValueAcrossAllTokens.toFixed(2)}`); // Output the final total balance
      setResults(results);
      setFilteredResults(results);
      setTotalBalance(totalUSDValueAcrossAllTokens); // Set the total USD balance (including TON + jettons)
      setTotalJettonBalances(jettonBalances);
    } catch (error) {
      console.error('Error fetching wallet balances:', error);
    } finally {
      setLoading(false);
    }
  };
  

  
  
  
  

  useEffect(() => {
    if (selectedJetton.input) {
      let totalFilteredJettonBalance = 0;
      let totalFilteredJettonUSD = 0;
      
      const filtered = results.map(walletData => {
        const filteredJettons = walletData.jettons.filter(jetton => {
          // Если есть точное совпадение, используем его
          if (selectedJetton.exact) {
            return jetton.name === selectedJetton.exact;
          }
          // Иначе фильтруем по частичному совпадению
          return jetton.name.toLowerCase().includes(selectedJetton.input.toLowerCase());
        });

        filteredJettons.forEach(jetton => {
          totalFilteredJettonBalance += parseFloat(jetton.count);
          totalFilteredJettonUSD += parseFloat(jetton.usdValue);
        });

        return {
          ...walletData,
          jettons: [
            walletData.jettons.find(j => j.name === 'TON'),
            walletData.jettons.find(j => j.name === 'Tether USD'),
            ...filteredJettons
          ].filter(j => j !== undefined)
        };
      });

      setFilteredResults(filtered);
      setTotalJettonBalances({
        [selectedJetton.exact || selectedJetton.input]: totalFilteredJettonBalance,
        [`${selectedJetton.exact || selectedJetton.input}_usd`]: totalFilteredJettonUSD
      });
    } else {
      const filtered = results.map(walletData => ({
        ...walletData,
        jettons: [
          walletData.jettons.find(j => j.name === 'TON'),
          walletData.jettons.find(j => j.name === 'Tether USD'),
          ...walletData.jettons.filter(jetton =>
            jetton.name !== 'TON' && jetton.name !== 'Tether USD'
          ).slice(0, 5)
        ].filter(j => j !== undefined)
      }));

      setFilteredResults(filtered);
    }
  }, [selectedJetton, results]);
  
  const handleSortByBalance = () => {
    if (sortedDescending) {
      setFilteredResults([...filteredResults].sort((a, b) => b.totalUSDValue - a.totalUSDValue));
    } else {
      setFilteredResults([...filteredResults].sort((a, b) => parseInt(a.walletAddress.match(/Wallet (\d+):/)[1]) - parseInt(b.walletAddress.match(/Wallet (\d+):/)[1])));
    }
    setSortedDescending(!sortedDescending);
  };

  const JettonList = ({ jettons }) => (
    <div className="jettons-list">
      {jettons.map((jetton, index) => (
        <div key={index} className="jetton-item">
          <div className="jetton-name">{jetton.name}</div>
          <div className="jetton-balance">{parseFloat(jetton.count).toFixed(4)}</div> {/* Only 4 decimals */}
          <div className="jetton-price">${parseFloat(jetton.price).toFixed(4)}</div> {/* Only 4 decimals */}
          <div className="jetton-usd-value">${parseFloat(jetton.usdValue).toFixed(4)}</div> {/* Only 4 decimals */}
        </div>
      ))}
    </div>
  );
  

  return (
    <div className="ton-wallet-checker">
      <div className="header-section">
        <h1>TON Wallet Balance Checker</h1>
      </div>

      <div className="input-section">
      <textarea
          rows="10"
          value={walletAddresses.join('\n')}
          onChange={(e) => {
            const addresses = e.target.value.split('\n').filter(address => address.trim() !== ''); // filter out empty lines
            setWalletAddresses(addresses);
          }}
          placeholder="Enter wallet addresses, one per line"
          className="wallet-input"
        />


        <div className="min-balance-input">
          <label>Filter by Jetton: </label>
          <TokenAutocomplete
            value={selectedJetton.input}
            onChange={setSelectedJetton}
            suggestions={tokenSuggestions}
          />
        </div>

        <button onClick={handleSubmit} disabled={loading} className="check-button">
          {loading ? 'Loading...' : 'Check Balances'}
        </button>
        <button onClick={handleSortJettons} disabled={loading} className="check-button" style={{marginTop: '5px'}}>
            Sort Tokens by USD Value {sortedJettonsDescending ? '(Ascending)' : '(Descending)'}
          </button>

        <button onClick={handleSortByBalance} disabled={loading} className="check-button" style={{marginTop:'5px'}}>
          Sort by Balance {sortedDescending ? '(Ascending)' : '(Descending)'}
        </button>
      </div>

      <div className="progress-section">
        <h2>Progress</h2>
        <p>{`Processed: ${progress.processedCount} / ${progress.totalWallets}`}</p>
      </div>

      <div className="total-balance">
        Total Balance in USD: ${totalBalance.toFixed(4)}
      </div>


      {selectedJetton && (
        <div className="total-jetton-balances">
          <h2>Total Jetton Balances</h2>
          {Object.keys(totalJettonBalances).map((jettonName, index) => (
            <div key={index} className="total-balance" style={{marginLeft:'5px'}}>
              <span>{jettonName}: </span>
              <span>{parseFloat(totalJettonBalances[jettonName]).toFixed(4)}</span> {/* Show only 4 decimal places */}
            </div>
          ))}

        </div>
      )}

      <div className="wallet-table-container">
    <table className="results-table">
      <thead>
        <tr>
          <th>Total USDT Value</th>
          <th>Wallet Address</th>
          <th>Jettons</th>
        </tr>
      </thead>
      <tbody>
        {filteredResults.map((walletData, index) => (
          <tr key={index}>
            <td className="total-usd-value">${walletData.totalUSDValue}</td>
            <td>{walletData.walletAddress}</td>
            <td className="jettons-cell">
              <JettonList jettons={walletData.jettons} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
    </div>
  );
};

export default TonWalletChecker;
